





























































import { Component } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import format from '@/filter/dateFormat';
import { SearchableMixin } from '@/views/mixin';
import { ApiResponse, Pagination } from '@/components/types';
import AppTable from '@/components/AppTable.vue';
import {
  FETCH_CREATOR_INBOX,
  FETCH_INBOX_COUNT,
  FETCH_INBOX_FILES,
  GET_INBOX_FILES
} from '@/modules/fileManagement/store';
import { File, MimeType } from '@/modules/fileManagement/types';
import ListSearchDialog from '@/components/ListSearchDialog.vue';
import UploadInboxFilesDialog from '@/modules/fileManagement/components/UploadInboxFilesDialog.vue';
import MoveInboxFileDialog from '@/modules/fileManagement/components/MoveInboxFileDialog.vue';
import RemoveFileDialog from '@/modules/fileManagement/components/RemoveFileDialog.vue';
import FileDownload from '@/modules/fileManagement/components/FileDownload.vue';
import ViewFileDialog from '@/modules/fileManagement/components/ViewFileDialog.vue';

const FileManagement = namespace('fileManagement');

@Component({
  components: {
    ViewFileDialog,
    FileDownload,
    RemoveFileDialog,
    MoveInboxFileDialog,
    UploadInboxFilesDialog,
    ListSearchDialog,
    AppTable
  }
})
export default class Inbox extends SearchableMixin {
  @FileManagement.Getter(GET_INBOX_FILES) inbox!: File[];
  @FileManagement.Action(FETCH_INBOX_FILES) fetchInbox!: () => Promise<ApiResponse>;
  @FileManagement.Action(FETCH_INBOX_COUNT) fetchCount!: () => Promise<ApiResponse>;
  @FileManagement.Action(FETCH_CREATOR_INBOX) fetchCreatorInbox!: () => Promise<ApiResponse>;

  loading: boolean = false;
  error: any = null;

  supportedViews: MimeType[] = [
    MimeType.JPG,
    MimeType.PDF,
    MimeType.PNG
  ];

  viewFile: File | null = null;
  moveFile: File | null = null;
  deleteFile: File | null = null;

  pagination: Pagination = {
    sortBy: ['created'],
    itemsPerPage: -1,
    page: 1,
    descending: true
  };

  get items(): File[] {
    return this.inbox.map((file) => ({
      ...file,
      createdTrans: format(file.created, 'dd.MM.yyyy'),
    }));
  }

  get headers() {
    return [
      { align: 'left', text: this.$i18n.t('common.created'), value: 'created' },
      { align: 'left', text: this.$i18n.t('common.label'), value: 'filename' },
      { align: 'left', text: this.$i18n.t('common.conservatee'), value: 'conservatee' },
      ...(this.isAdmin() ? [{ align: 'left', text: this.$i18n.t('common.conservator'), value: 'owner.name' }] : []),
      { align: 'right', text: this.$i18n.t('common.actions'), value: null, sortable: false },
    ];
  }

  async fetch() {
    try {
      this.loading = true;

      await this.fetchInbox();

      this.fetchCount().catch(console.error);
    } catch (error) {
      this.error = error;
    }

    this.loading = false;
  }

  get employee() {
    if (this.isSupport()) {
      return undefined;
    }

    return this.$auth.user().id;
  }

  created() {
    return this.fetch();
  }
}
