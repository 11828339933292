











































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import ErrorAlert from '@/components/ErrorAlert.vue';
import AppDropzone from '@/modules/fileManagement/components/AppDropzone.vue';
import ConservatorSelect from '@/modules/conservator/components/ConservatorSelect.vue';
import ConservatorshipSelect from '@/modules/conservatorship/components/ConservatorshipSelect.vue';
import { baseURL } from "@/modules/fileManagement/store";

@Component({
  components: {
    ConservatorshipSelect,
    ConservatorSelect,
    AppDropzone,
    ErrorAlert
  }
})
export default class UploadInboxFilesDialog extends Vue {
  @Prop({ type: String }) employee!: string;

  error: any = null;

  conservator: string = '';
  conservatorship: string = '';
  dialog: boolean = false;
  close: Function = () => {};

  get additional(): { [option: string]: any } {
    return {
      'employee_id': this.conservator,
      'conservatorship_id': this.conservatorship
    }
  }

  get url(): string {
    return baseURL() + '/file-service/inbox/upload'
  }

  @Watch('dialog')
  watchDialogClosed(value: boolean) {
    if (value) {
      this.conservator = this.employee || '';

      return;
    }

    this.conservator = '';
    this.error = null;
    this.close();
  }
}
