
































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { File, MoveFile } from '../types';
import { ApiResponse } from '@/components/types';
import { FETCH_TREE_BY_CONSERVATORSHIP, GET_TREE_BY_CONSERVATORSHIP, MOVE_FILE } from '@/modules/fileManagement/store';
import ConservatorshipSelect from '@/modules/conservatorship/components/ConservatorshipSelect.vue';

const FileManagement = namespace('fileManagement');

@Component({
  components: {
    ConservatorshipSelect
  }
})
export default class MoveInboxFileDialog extends Vue {
  @Prop({ type: Object }) value!: File | null;

  @FileManagement.Action(MOVE_FILE) move!: (command: MoveFile) => Promise<ApiResponse>;
  @FileManagement.Action(FETCH_TREE_BY_CONSERVATORSHIP) fetchTree!: (conservatorship: string) => Promise<ApiResponse>;
  @FileManagement.Getter(GET_TREE_BY_CONSERVATORSHIP) getTree!: (conservatorship: string) => File[];

  error: any = null;
  dialog: boolean = false;
  loading: boolean = false;
  conservatorship: string = '';

  file: MoveFile = {
    file_id: '',
    folder_id: ''
  };

  get tree() {
    return this.getTree(this.conservatorship) || [];
  }

  get folder() {
    return [this.file.folder_id];
  }

  set folder(folder) {
    if (folder.length === 0) {
      this.file.folder_id = '';
      return;
    }

    this.file.folder_id = folder[folder.length - 1];
  }

  @Watch('conservatorship')
  async watchConservatorship(conservatorship: string) {
    if (!conservatorship) return;

    this.loading = true;

    const { error } = await this.fetchTree(conservatorship);

    if (error) {
      this.error = error;
    }

    this.loading = false;
  }

  @Watch('dialog')
  watchDialogClosed(value: boolean) {
    if (value) return;

    setTimeout(() => {
      this.clear();
    }, 200);
  }

  @Watch('value')
  watchFile(file: File) {
    if (!file) return;

    this.init();

    setTimeout(() => {
      this.dialog = true;
    }, 200);
  }

  clear() {
    this.file = {
      file_id: '',
      folder_id: ''
    };

    this.error = null;
    this.$emit('input', null);
    // @ts-ignore
    this.$refs.form.reset();
  }

  init() {
    if (!this.value) return;

    if (this.value.conservatorship) {
      this.conservatorship = this.value.conservatorship.id;
    }

    this.file = {
      file_id: this.value.id,
      folder_id: this.value.folderId
    };
  }

  onSuccess() {
    this.dialog = false;
    this.$emit('success');
  }

  async save(): Promise<ApiResponse> {
    this.error = null;

    if (!this.file.folder_id) return {};

    return this.move(this.file);
  }
}
